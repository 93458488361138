<!--
 * @Descripttion:
 * @version:
 * @Author: hutian
 * @Date: 2021-03-16 09:47:53
 * @LastEditors: hutian
 * @LastEditTime: 2021-08-10 10:12:49
-->
<template>
  <a-modal
    :title="handle === 'add' ? '新增' : '编辑'"
    style="top: 8px"
    :width="1000"
    v-model="visible"
    @ok="toSubmit"
    okText="提交"
    @cancel="visible == false"
    :maskClosable="false"
  >
    <a-form-model
      ref="form"
      :model="rowData"
      :rules="formRule"
      layout="horizontal"
      :label-col="{ span: 4 }"
      :wrapper-col="{ span: 20 }"
    >
      <a-form-model-item label="分类名称" prop="">
        <a-input v-model="rowData.categoryName" disabled placeholder="分类名称"></a-input>
      </a-form-model-item>
      <a-form-model-item label="是否使用折扣&政策" prop="">
        <a-checkbox-group v-model="usedRebatePolicy" @change="onChange">
          <a-checkbox :value="item.id" :disabled="item.disabled" v-for="(item, index) in dataList" :key="index">
            {{ item.name }}
          </a-checkbox>
        </a-checkbox-group>
      </a-form-model-item>
      <a-form-model-item label="是否使用积分" prop="" :autoLink="false" ref="test">
        <a-select v-model="usedIntegral" placeholder="请选择" @change="IntegralChange">
          <a-select-option :value="1">是</a-select-option>
          <a-select-option :value="2">否</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="是否返还积分" prop="" :autoLink="false" ref="test">
        <a-select v-model="returnIntegral" placeholder="请选择" @change="handleChange">
          <a-select-option :value="1">是</a-select-option>
          <a-select-option :value="2">否</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="返还积分比例" v-if="disabled != 'hide'" prop="">
        <div style="display: flex">
          <a-input-number
            :min="1"
            :max="100"
            v-model="rowData.returnIntegralNumStr"
            style="width: 50%; margin-right: 5px"
            placeholder="返还积分比例"
            :formatter="(value) => `${value}%`"
            :parser="(value) => value.replace('%', '')"
          ></a-input-number>
        </div>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
import {
  editCategoryRebatePolicy,
  selectByIdCategoryRebatePolicy,
  addCategoryRebatePolicy,
} from '../api/CategoryRebatePolicyApi'
export default {
  data() {
    return {
      visible: false,
      handle: 'add',
      rowData: {},
      // 表单验证
      formRule: {
        categoryId: [{ required: true, message: '请输入分类ID', trigger: 'blur' }],
        usedRebatePolicy: [{ type: 'array', required: true, message: '请选择是否折扣&政策', trigger: 'change' }],
        usedIntegral: [{ required: true, message: '请选择是否使用积分', trigger: 'change' }],
        returnIntegral: [{ required: true, message: '请选择是否返还积分', trigger: 'change' }],
        returnIntegralNum: [{ required: true, message: '请输入返还积分比例', trigger: 'blur' }],
      },
      dataList: [
        {
          id: 1,
          name: '折扣',
          disabled: false,
        },
        {
          id: 2,
          name: '政策',
          disabled: false,
        },
        {
          id: 3,
          name: '折扣&政策 2选1',
          disabled: false,
        },
      ],
      disabled: 'hide',
      usedIntegral: '',
      returnIntegral: '',
      usedRebatePolicy: [],
    }
  },
  methods: {
    /**
     * 获取行数据
     */
    setRowData(row, handle) {
      console.log(row)
      this.handle = handle
      this.visible = true
      this.rowData = {}
      if (handle == 'edit') {
        selectByIdCategoryRebatePolicy(row.id).then((res) => {
          this.rowData = res.body
          if (res.body.usedRebatePolicy == 1 || res.body.usedRebatePolicy == 2 || res.body.usedRebatePolicy == 3) {
            this.usedRebatePolicy = [res.body.usedRebatePolicy]
          } else if (res.body.usedRebatePolicy == 4) {
            this.usedRebatePolicy = [1, 2]
          }
          this.usedIntegral = res.body.usedIntegral ? 1 : ''
          this.returnIntegral = res.body.returnIntegral ? 1 : ''
          if (this.returnIntegral == 1) {
            this.disabled = 'show'
          }else {
            this.disabled = 'hide'
          }
          if (this.usedRebatePolicy[0] == 1 || this.usedRebatePolicy[0] == 2) {
            this.dataList[2].disabled = true
          } else if (this.usedRebatePolicy[0] == 3) {
            this.dataList[0].disabled = true
            this.dataList[1].disabled = true
          } else {
            this.dataList[0].disabled = false
            this.dataList[1].disabled = false
            this.dataList[2].disabled = false
          }
        })
      }
    },
    onChange(val) {
      if (val[0] == 1 || val[0] == 2) {
        this.dataList[2].disabled = true
      } else if (val[0] == 3) {
        this.dataList[0].disabled = true
        this.dataList[1].disabled = true
      } else {
        this.dataList[0].disabled = false
        this.dataList[1].disabled = false
        this.dataList[2].disabled = false
      }
      if (val.length <= 1) {
        this.rowData.usedRebatePolicy = val[0]
      } else if (val.length == 2) {
        this.rowData.usedRebatePolicy = 4
      }
    },
    handleChange(val) {
      this.$refs.test.onFieldChange()
      if (val == 1) {
        this.disabled = 'show'
        this.rowData.returnIntegral = true
      } else if (val == 2) {
        this.disabled = 'hide'
        this.rowData.returnIntegral = false
      }
    },

    IntegralChange(val) {
      if (val == 1) {
        this.rowData.usedIntegral = true
      } else if (val == 2) {
        this.rowData.usedIntegral = false
      }
    },
    /**
     * 表单提交
     */
    toSubmit() {
      console.log(!this.rowData.usedIntegral)
      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          return
        }
        // if (this.rowData.returnIntegralNum) {
        //   return this.$notification.warning({ message: '请输入返还积分比例' })
        // }
        // if(!this.rowData.usedIntegral) {
        //     return this.$notification.warning({ message: '请选择是否使用积分'})
        // }
        // if(!this.rowData.returnIntegral) {
        //     return this.$notification.warning({ message: '请选择是否返还积分'})
        // }
        const res =
          this.handle === 'add'
            ? await addCategoryRebatePolicy(this.rowData)
            : await addCategoryRebatePolicy(this.rowData)
        if (res.code === 200) {
          this.$notification.success({ message: res.message })
          this.$emit('reload')
          this.visible = false
          this.rowData = {}
        } else {
          this.$notification.error({ message: res.message })
        }
      })
    },
  },
  created() {},
}
</script>

<style lang="less" scoped>
</style>
